
import "./../style.css"

import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom"

const GameList = ({ name, ex, out1 }) => {
    const [open, setOPen] = useState(false);
    const [Ss, setSs] = useState("");


    useEffect(() => { setSs(out1) }, [out1]);

    const inputClic2 = (pp) => {
        let res = pp.reduce(function (sum, elem) {
            return sum + (elem.win+elem.extraSum);
        }, 0);
        return res.toFixed(2)
    }

    const dated = (pp) => {
        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        var d = new Date(pp);
        return d.toLocaleDateString("ru-RU", options)
    }

    const handleClick = (isOpen) => {
        setOPen(isOpen)
    }

    return (
        <>
            <div onClick={(e) => { handleClick(!open) }}>
                {Ss? <><div className="frame"><h1 className="line ">{dated(Object.keys(Ss.list)[ex])}</h1>
                {inputClic2(Ss.list[Object.keys(Ss.list)[ex]]) > 0 ? <p className="green line">{"+" + inputClic2(Ss.list[Object.keys(Ss.list)[ex]])}</p> : <p className="red line">{"" + inputClic2(Ss.list[Object.keys(Ss.list)[ex]])}</p>}</div>
                <div>{open && Ss.list[Object.keys(Ss.list)[ex]].map(el => (<li className="li" id={el.gameNumber}><NavLink className="MuiL" to={"/resaultList/"+ el.gameNumber}>{"слот №" + el.slotNumber +","+ " роль: " + el.roleGame +","+  " баллы: " + (el.win+el.extraSum)}</NavLink></li>))}</div></> : ""}
            </div>
        </>
    )
}

export default GameList