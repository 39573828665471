import "./style.css"

import axios from "axios";
import React from "react";
import { NavLink } from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

const baseUrl2 = 'https://7reds.ru/api/mafia/getRating?full=1'
const baseUrl = 'https://7reds.ru/api/mafia/getRating?full=0'

class Users extends React.Component {
    constructor(props) {
        super(props)
        axios.get(baseUrl).then((res) => {
            this.setState({ users: res.data.ratingList, users1: res.data.ratingList, nam: res.data.nomination, inf: res.data.info })
        })

        this.state = {
            users: [],
            users1: [],
            nam:{},
            inf:{},
            full: false
        }
    }

    render() {

        const clickMounth = () => {
            this.setState({ users: [], users1: [], full: false, nam:{} })
            axios.get(baseUrl).then((res) => {
                this.setState({ users: res.data.ratingList, users1: res.data.ratingList,nam: res.data.nomination, inf: res.data.info})
            })
            document.getElementById("e").value = null;
        }
        const clickYear = () => {
            this.setState({ users: [], users1: [], full: true, nam:{} })
            axios.get(baseUrl2).then((res) => {
                this.setState({ users: res.data.ratingList, users1: res.data.ratingList, nam: res.data.nomination, inf: res.data.info })
            })
            document.getElementById("e").value = null;
        }
        const filter = () => {
            if (document.getElementById("e").value.length > 0) {
                this.setState({
                    users: this.state.users1.filter(el =>
                        el.nickName.substring(0, document.getElementById("e").value.length).toLowerCase() == document.getElementById("e").value.toLowerCase())
                })
            }
            else this.setState({ users: this.state.users1 })
        }
        return (
            <main className="section">
                <div className="container">
                    <h1 className="user1">{this.state.nam.bestRed && "Лучший красный - "+this.state.nam.bestRed+" | "+"Лучший черный - "+this.state.nam.bestBlack+" | "+"Лучший дон - "+this.state.nam.bestDon+" | "+"Лучший шериф - "+this.state.nam.bestCom+" | "+"MVP - "+this.state.nam.mvp+" | "+"Враг государства - "+this.state.nam.mostShoot+" | "+"Закрой за мной дверь - "+this.state.nam.mostNine+" | "+"Птица-говорун - "+this.state.nam.mostFoul}</h1>
                    <div className="user3"><p className="user2">{this.state.inf.mirWin && (this.state.inf.mafWin/(this.state.inf.mirWin+this.state.inf.mafWin)*100).toFixed(2)+"%"}</p><LinearProgress color="inherit" variant="determinate" value={this.state.inf.mirWin/(this.state.inf.mirWin+this.state.inf.mafWin)*100} sx={{minHeight: 20, width: "100%", color:"red", backgroundColor:"black", position: "relative" }} />
                 <p>{this.state.inf.mirWin && (this.state.inf.mirWin/(this.state.inf.mirWin+this.state.inf.mafWin)*100).toFixed(2)+"%"}</p></div>
                    <div className="user1"><h1 className="header1">Рейтинг :</h1><h1 className={!this.state.full ? "titleRating focus" : "titleRating"} onClick={clickMounth}>Месяца</h1><h1 className={this.state.full ? "titleRating focus" : "titleRating"} onClick={clickYear}>Годовой</h1></div>
                    <input className="user1 ff" placeholder="Ник" onInput={() => filter()} id="e"></input>
                    {this.state.users.length != 0 ? this.state.users.map((el) =>
                        <NavLink to={"/player/" + el.nickName}>
                            <div className="user" key={el.nickName}>
                            <h1 className="elo">{"ELO "+el.elo.toFixed(2)}</h1>
                                <CircularProgress variant="determinate" sx={{
                                    minWidth: 80,
                                    minHeight: 80,
                                    color: 'grey',
                                    position: "absolute",

                                }} value={100} />
                                <CircularProgress variant="determinate" sx={{
                                    minWidth: 80,
                                    minHeight: 80,
                                    color: 'white',
                                    position: "relative"
                                }} value={el.procentWin} />
                                <center><h3 className="bbbb">{((el.procentWin).toFixed(2)).replace(/0*$/,"").replace(/\.$/gm, '') + '%'}</h3></center>
                                <div className="b">
                                    <h3>{el.top + ' место'}</h3>
                                    {<p>{'Баллов: ' + el.points.toFixed(2)}</p>}
                                    <p>{'Ник: ' + el.nickName}</p>
                                </div>
                            </div>
                        </NavLink>
                    )
                        : <center><CircularProgress sx={{
                            minWidth: 250,
                            minHeight: 250,
                            color: 'black',
                        }} /></center>}
                </div>
            </main>
        )
    }
}

export default Users;
