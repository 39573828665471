import vk from "./../../img/icons/vk2.svg"
import twitter from "./../../img/icons/tele.svg"
import gitHub from "./../../img/icons/tube.svg"
import goMafia from "./../../img/icons/1.webp"

import React from "react"


import "./style.css"

const Footer = () => {
    return (     
    <footer className="footer">
    <div className="container">
        <div className="footer__wrapper">
            <ul className="social">
                <li className="social__item"><a href="https://vk.com/7reds" target="_blank"><img src={vk} alt="Link"/></a></li>
                <li className="social__item"><a href="https://t.me/mafia7reds" target="_blank"><img src={twitter} alt="Link" /></a></li>
                <li className="social__item"><a href="https://www.youtube.com/@sevenreds9970" target="_blank"><img src={gitHub} alt="Link" /></a></li>
                <li className="social__item1"><a href="https://gomafia.pro/club/184" target="_blank"><img src={goMafia} alt="Link" /></a></li>
            </ul>
            <div className="copyright">
                <p>© 2023 Noname Production</p>
            </div>
        </div>
    </div>
    </footer> );
}
 
export default Footer;