import "./style.css"
import React from "react";
import axios from "axios";


const Modal = ({active,setActive}) => {
    const sendRew =()=>{
        const regex = /^[А-Яа-яA-Za-z0-9]+.*/;
        if (document.getElementById("n11").value.length > 1 && document.getElementById("t12").value.length > 4){
        axios.post("https://7reds.ru/api/mafia/list", {
            text: "От: "+document.getElementById("n11").value+"\nОтзыв: "+document.getElementById("t12").value
        })
        document.getElementById("n11").value=null;
        document.getElementById("t12").value=null;
        setActive(false)
    }
    else alert("Чтобы оставить отзыв, заполните все поля");
    }

    return (
        <div className={active?"modal active":"modal"} onClick={()=>setActive(false)}>
            <div className="modal__content" onClick={e=>e.stopPropagation()}>
            <input className="input name" id="n11" placeholder="Введите ваше имя" name="name" minLength="2" maxLength="30" pattern="^[А-Яа-яA-Za-z0-9]+.*" required />
            <textarea className="input text" id="t12" placeholder="Оставьте отзыв" name="name" minLength="5" required />
            <center><button className="btn" onClick={sendRew}>Отправить</button><button className="btn" onClick={()=>setActive(false)}>Закрыть</button></center>
            </div>
        </div>
    );
}

export default Modal;