import "./styles/main.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react"

import NavBar from "./components/navbar/NavBar";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Users from "./components/users/Users";
import Player from "./components/players/player";
import Games from "./components/games/Games";
import List from "./components/list/List";
import Add from "./components/add/Add";
import Contacts from "./components/contacts/Contacts";
import ScrollToTop from "./components/utils/scrollToTop";
import Modal from "./components/modal/Modal";
import { useState } from "react";
import goMafia from "./img/icons/2.png"
import TopScr from "./components/toTop/TopScr";
import RewriteList from "./components/list/RewriteList";

function App() {
  const [modalActive, setModalActive] = useState(false)
  return (
    <div className="App">
      <Router>
          <ScrollToTop />
          <NavBar />
          <Routes>
            <Route path="/" element={<Header />}></Route>
            <Route path="/rating" element={<Users />}></Route>
            <Route path="/games" element={<Games />}></Route>
            <Route path="/player/:name" element={<Player />}></Route>
            <Route path="/contacts" element={<Contacts />}></Route>
            <Route path="/resaultList/:name" element={<List />}></Route>
            <Route path="/add" element={<Add />}></Route>
            <Route path="/rewrite" element={<RewriteList />}></Route>
          </Routes>
          <Footer />
        <div id={"rew3"} className="rew3" onClick={() => setModalActive(true)}><div className="rew2"><img src={goMafia} alt="Link" className="rew" /></div></div>
        <Modal active={modalActive} setActive={setModalActive} />
        <TopScr />
      </Router>
    </div>
  );
}

export default App;
