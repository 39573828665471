import "./style.css"
import pc from "./../../img/icons/pc.svg"
import exit from "./../../img/icons/exit.svg"
import don from "./../../img/icons/don.svg"
import maf from "./../../img/icons/maf.svg"
import com from "./../../img/icons/com.svg"
import mir from "./../../img/icons/mir.svg"

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';

const List = () => {

    const [input, setInput] = useState("");
    const baseUrl = 'https://7reds.ru/api/mafia/getGame'
    const { name } = useParams();
    
    const getRoleIcone = (role) =>{
     if(role=="Дон") return don;
     if(role=="Маф") return maf;
     if(role=="Шер") return com;
     if(role=="Мир") return mir;
    }

    let i = 1;
    useEffect(() => { axios.get(baseUrl).then(res => setInput(res.data.gamesList)) }, []);
    useEffect(() => { if(document.getElementById(name)){document.getElementById(name).scrollIntoView({ block: "center", behavior: "auto" })} }, [document.getElementById(name)]);
    return (
        <main>
            
            {input[0] != null ?
                <>
                    {input.map((data) =>
                       <table id={data[0].gameNumber} className={data[0].gameNumber==name?"frame tt table":"frame table"}>
                            <tr  >
                                <h2 className="he">{data[0].dateGame}</h2>
                                <h2 className="he">игра № {data[0].gameNumberOfDay}</h2>
                                <h2 className="he">ведущий: {data[0].host}</h2>
                                <h2 className="he qwe"> {data[0].gameNumber}</h2>
                            </tr>
                            <tr>
                                <td className ="td" align="center" >Слот</td >
                                <td className ="td" id="nick" align="center">Ник</td >
                                <td className ="td" align="center">Роль</td >
                                <td className ="td" align="center">Фолы</td >
                                <td className ="td" align="center">Победа</td >
                                <td className ="td" align="center">+ Доп</td >
                                <td className ="td" align="center">- Доп</td >
                                <td className ="td" align="center">ЛХ</td >
                                <td className ="td" align="center">Ʃ Доп</td >
                                <td className ="td" align="center">Итог</td >
                                <td className ="td"align="center">ELO</td >
                            </tr>
                            {data.map((x) =>
                                <tr >
                                    <td className ="td" align="center">{x.slotNumber}</td>
                                    <td className ="td" id="nick" align="left"><div></div>{x.nick+" "}{x.firstShot ? <div className="rtr"><img src={pc} alt="Link" /></div>:""}{x.nine ? <div className="rtr"><img src={exit} alt="Link" /></div>:""}</td>
                                    <td className ="td" align="center"><div className="rtr"><img src={getRoleIcone(x.roleGame)} alt="Link" /></div></td>
                                    <td className ="td" align="center">{x.foul}</td>
                                    <td className ="td" align="center">{x.win}</td>
                                    <td className ="td" align="center">{x.extraPlus}</td>
                                    <td className ="td" align="center">{x.extraMinus}</td>
                                    <td className ="td" align="center">{x.lh}</td>
                                    <td className ="td" align="center">{x.extraSum}</td>
                                    <td className ="td" align="center"><p align="center" className={(x.extraSum +x.win)>0?"green1":"red1"}>{x.extraSum +x.win}</p></td>
                                    <td className ="td"align="center"><p align="center" className={(x.difElo)>0?"green1":"red1"}>{x.difElo!=null?x.difElo>0?"+"+x.difElo.toFixed(2):x.difElo.toFixed(2):""}</p></td>
                                </tr>
                            )}
                        </table>
                    )}
                </>
                : <center><CircularProgress sx={{
                    minWidth: 250,
                    minHeight: 250,
                    color: 'black',
                }} /></center>}
        </main>
    );
}

export default List;