import React from "react"

const Contacts = () => {
    return (     
        <main className="section">
        <div className="container">
                <h1 className="title-1">Контакты</h1>

                <ul className="content-list">
                    <li className="content-list__item">
                        <h2 className="title-2">Где?</h2>
                        <p>Ростов-на-Дону, Россия</p>
                    </li>
                    <li className="content-list__item">
                        <h2 className="title-2">Telegram / WhatsApp</h2>
                        <p><a href="tel:+79518257109">+7 (951) 825-71-09</a></p>
                    </li>
                    <li className="content-list__item">
                        <h2 className="title-2">Email</h2>
                        <p><a href="mailto:mafia7reds@gmail.com">mafia7reds@gmail.com</a></p>
                    </li>
                </ul>

        </div>
    </main> );
}
 
export default Contacts;