import "./../style.css"
import pc from "./../../../img/icons/pc.svg"
import exit from "./../../../img/icons/exit.svg"
import don from "./../../../img/icons/don.svg"
import maf from "./../../../img/icons/maf.svg"
import com from "./../../../img/icons/com.svg"
import mir from "./../../../img/icons/mir.svg"

import React, { useEffect, useState } from "react";
import axios from "axios";


const OneGame = ({ arr, name, reload }) => {

    const [redactor, setRedactor] = useState(false);
    let i = 1;

    const getSlot = () => {
        if (i < 10) {
            i++;
        }
        else i = 1;
    }

    const getRoleIcone = (role) => {
        if (role == "Дон") return don;
        if (role == "Маф") return maf;
        if (role == "Шер") return com;
        if (role == "Мир") return mir;
    }

    const click = () => {
        setRedactor(!redactor)
    }

    const send = (event) => {
        const baseUrl = 'https://7reds.ru/api/mafia/deleteGame?gameNumber=';
        const baseUrl2 = 'https://7reds.ru/api/mafia/addGame?rewrite=true';
        const arr = [];
        let i = 1;
        let parent = event.target.parentNode.parentNode;
        let final = parent.querySelectorAll("tr").length - 1;
        while (i < final) {
            arr.push({
                gameNumber: Number(parent.querySelector("#uid").innerText),
                dateGame: parent.querySelector('#date').value,
                host: parent.querySelector('#host').value,
                slotNumber: i,
                roleGame: parent.querySelector('#role' + i).value,
                nick: parent.querySelector('#name' + i).value,
                foul: Number(parent.querySelector('#foul' + i).value),
                firstShot: parent.querySelector('#first' + i).checked,
                nine: parent.querySelector('#nine' + i).checked,
                win: Number(parent.querySelector('#win' + i).value),
                extraSum: Number(parent.querySelector('#extraPlus' + i).value.replaceAll(',', '.')) + Number(parent.querySelector('#lh' + i).value.replaceAll(',', '.')) - Number(parent.querySelector('#extraMinus' + i).value.replaceAll(',', '.')),
                extraPlus: Number(parent.querySelector('#extraPlus' + i).value.replaceAll(',', '.')),
                extraMinus: Number(parent.querySelector('#extraMinus' + i).value.replaceAll(',', '.')),
                lh: Number(parent.querySelector('#lh' + i).value.replaceAll(',', '.')),
                gameNumberOfDay: Number(parent.querySelector('#gameNumber').value),
                difElo: Number(parent.querySelector('#elo' + i).innerText),
            })
            i++
        }
        axios.delete(baseUrl + Number(parent.querySelector("#uid").innerText));
        axios.post(baseUrl2, arr);
        setRedactor(!redactor)
        setTimeout(reload, 2000)
    }

    useEffect(() => { }, [redactor]);
    return (
        <table id={arr[0].gameNumber} className={arr[0].gameNumber == name ? "frame tt table" : "frame table"}>
            <tr  >
                <input className="mz" id="date" disabled={redactor ? 0 : 1} defaultValue={arr[0].dateGame} />
                <h2 className="he" >игра № <input className="mz1" id="gameNumber" disabled={redactor ? 0 : 1} defaultValue={arr[0].gameNumberOfDay} /></h2>
                <h2 className="he" >ведущий: <input className="mz" id="host" disabled={redactor ? 0 : 1} defaultValue={arr[0].host} /></h2>
                <button className="mz3" onClick={click}>Редактировать</button>
                <button className={redactor ? "mz3" : "mz3 mz4"} onClick={(e) => send(e)} >Отправить</button>
                <h2 className="he qwe" id="uid"> {arr[0].gameNumber}</h2>
            </tr>
            <tr>
                <td className="td" align="center" >Слот</td >
                <td className="td" id="nick" align="center">Ник</td >
                <td className="td" align="center">Роль</td >
                <td className="td" align="center">Фолы</td >
                <td className="td" align="center">Победа</td >
                <td className="td" align="center">+ Доп</td >
                <td className="td" align="center">- Доп</td >
                <td className="td" align="center">ЛХ</td >
                {!redactor ? <td className="td" align="center">Ʃ Доп</td > : ""}
                {!redactor ? <td className="td" align="center">Итог</td > : ""}
            </tr>
            {arr.map((x) =>
                <tr >
                    <td className="td" align="center"><input id={"slot" + i} className="rewr" defaultValue={x.slotNumber} disabled /></td>
                    <td className="td" id="nick" align="left"><input className="rtrh" id={"name" + i} defaultValue={x.nick} disabled={redactor ? 0 : 1} />{x.firstShot ? <div className="rtr"><img src={pc} alt="Link" /></div> : ""}{x.nine ? <div className="rtr"><img src={exit} alt="Link" /></div> : ""}</td>
                    <td className="td" align="center"><div className="rewr" >{!redactor ? <div className="rtr"><img src={getRoleIcone(x.roleGame)} alt="Link" /> </div> : <input className="rewr" id={"role" + i} defaultValue={x.roleGame} />}</div></td>
                    <td className="td" align="center"><input className="rewr" defaultValue={x.foul} id={"foul" + i} disabled={redactor ? 0 : 1} /></td>
                    <td className="td" align="center"><input className="rewr" defaultValue={x.win} id={"win" + i} disabled={redactor ? 0 : 1} /></td>
                    <td className="td" align="center"><input className="rewr" defaultValue={x.extraPlus} id={"extraPlus" + i} disabled={redactor ? 0 : 1} /></td>
                    <td className="td" align="center"><input className="rewr" defaultValue={x.extraMinus} id={"extraMinus" + i} disabled={redactor ? 0 : 1} /></td>
                    <td className="td" align="center"><input className="rewr" defaultValue={x.lh} id={"lh" + i} disabled={redactor ? 0 : 1} /></td>
                    {!redactor ? <td className="td" align="center"><input className="rewr" defaultValue={x.extraSum} disabled /></td> : ""}
                    {redactor ? <td className="td" align="center"><input className="vbv" type="checkbox" defaultChecked={x.firstShot} id={"first" + i}></input><p className="vbv">ЛХ</p><input className="vbv" type="checkbox" defaultChecked={x.nine} id={"nine" + i}></input><p className="vbv">В9</p></td> : <td className="td" align="center"><input align="center" className={(x.extraSum + x.win) > 0 ? "green1 rewr" : "red1 rewr"} defaultValue={x.extraSum + x.win} disabled /></td>}
                    <td className ="td"align="center"><p id={"elo"+i} align="center" className={(x.difElo)>0?"green1":"red1"}>{x.difElo!=null?x.difElo>0?"+"+x.difElo.toFixed(2):x.difElo.toFixed(2):""}</p></td>
                    {getSlot()}
                </tr>
            )}
        </table>
    );
}

export default OneGame;