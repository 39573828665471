import "./style.css"
import up from "./../../img/icons/3.png"

import React, { useEffect, useState } from "react";

const TopScr = () => {
    const [backToTopButton, setbackToTopButton] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 400) {
                setbackToTopButton(true)
            } else {
                setbackToTopButton(false)
            }
        }
        )
    }, []);

    const scrollUp = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    return (
        <div className={backToTopButton ? "rew4":"vis1"} onClick={scrollUp}><div className="rew5"><img src={up} alt="Link" className="reww" /></div></div>
    );
}

export default TopScr;