
import React, { useEffect, useState } from "react";
import BasicSelect from "./BasicSelect";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const InputLine = ({ number, nameList, roles, fouls }) => {
    const [input, setInput] = useState(1);
    const [foul, setFoul] = useState(0);

    const click = (e) => {
        setInput(e.target.checked ? 0 : 1)
    }

    const clickFoul = (arg) => {
        if (!arg.nativeEvent) {setFoul(arg);}
        if (foul<4 && arg.nativeEvent) {setFoul(foul+1);}
    }

    return (
        <>
            <div className="heh">
                <div className="he bb"><BasicSelect ids={"name" + number} nameList={nameList} title={"Ник"} tr={140} /></div>
                <div className="he bb"><BasicSelect ids={"role" + number} nameList={roles} title={"Роль"} tr={75}/></div>
                <div className="he bb"><BasicSelect ids={"foul" + number} nameList={fouls} title={"Фол"} val={foul} arg={clickFoul} tr={35} /></div>
                <div className="he bb"><TextField id={"extraPlus" + number} label="+Доп" defaultValue="0" sx={{ maxWidth: 53 }}/>  </div>
                <div className="he bb"><TextField id={"extraMinus" + number} label="-Доп" defaultValue="0" sx={{ maxWidth: 53 }}/>  </div>
                <div className="he bb"><TextField id={"lh" + number} label="ЛХ" disabled={input} defaultValue="0" sx={{ maxWidth: 53 }}/></div>
                {/* <input id={"first" + number} className="he bb" type="checkbox" onClick={e => click(e)}></input> */}
                <div className="he bb">
                <div className="zzz">
                <FormControlLabel className="zzz" onClick={e => click(e)} control={<Checkbox id={"first" + number} />} label="ЛХ" />
                </div>
                <div className="zzz">
                <FormControlLabel control={<Checkbox id={"nine" + number}/>} label="В9" />
                {/* <input id={"nine" + number} className="he bb" type="checkbox"></input> */}
                </div>
                </div>
                <Button className="bb" variant="outlined" style={{ textTransform: "none", padding: "16px 0px" }} size="small" color="error" onClick={clickFoul}>Фол</Button>
            </div>
        </>
    )
}

export default InputLine