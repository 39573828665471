
import React, { useEffect, useState } from "react";
import BasicSelect from "./BasicSelect";
import TextField from '@mui/material/TextField';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const InputRightLine = () => {
    const [input, setInput] = useState(1);
    const [foul, setFoul] = useState(0);

    const theme = createTheme({
        typography: {
            fontSize: 12,
        },
    });
    

    return (
        <>
        <ThemeProvider theme={theme}>
            <div className="heh">
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "53px",width:"53px",}}}/>  </div>
            </div>
            <div className="heh m">
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            <div className="he6"><TextField size="small"  inputProps={{style: {padding: 7,height: "20px",width:"20px",}}}/>  </div>
            </div>
            </ThemeProvider>
        </> 
    )
}

export default InputRightLine