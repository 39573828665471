import "./style.css"

import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from '@mui/material/Button';
import InputLine from "./components/InputLine";
import InputRightLine from "./components/InputRightLine";
import TextField from '@mui/material/TextField';
import BasicSelect from "./components/BasicSelect";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import play from "./../../img/icons/play.svg"
import refresh from "./../../img/icons/refresh.svg"
import pause from "./../../img/icons/pause.svg"

const Add = () => {

    const [input, setInput] = useState("");
    const [nameList, setNameList] = useState([]);
    const baseUrl = 'https://7reds.ru/api/mafia/lastGame'
    const baseUrl1 = 'https://7reds.ru/api/mafia/getPlayers'
    const baseUrl2 = 'https://7reds.ru/api/mafia/addGame?rewrite=false'
    const baseUrl3 = 'https://7reds.ru/api/mafia/addPlayers'
    const playerNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const roles = ["Мир", "Маф", "Шер", "Дон"]
    const fouls = [0, 1, 2, 3, 4]
    const wins = ["Город", "Мафия"]
    const [vision, setVision] = useState(false);
    const [newp, setNewp] = useState(0);
    const [go, setGo] = useState(false);
    let i = 60;
    let c = false;
    let oo;

    const start = () => {
     if(!c) {
        oo = setInterval(start1, 1000);
        c=true;
     }
    }

    const stop = () => {
        clearInterval(oo);
        c=false;
    }

    const restart = () => {
        clearInterval(oo);
        i = 60;
        document.getElementById("zxcc").value = i;
        c=false;
    }

    const start1 = () => {
        if (i == 0) {
            restart()
            return
        }
        i--;
        document.getElementById("zxcc").value = i;
    }
    
    useEffect(() => { if (document.getElementById("rew3")) { document.getElementById("rew3").remove() } }, [document.getElementById("rew3")]);

    useEffect(() => {
        axios.get(baseUrl1).then((res) => {
            setNameList(res.data)
        })
    }, [newp]);

    useEffect(() => {
        axios.get(baseUrl).then((res) => {
            setInput(res.data)
        })
    }, []);

    const open = () => {
        setVision(!vision)
    }

    const addNick = () => {
        axios.post(baseUrl3,
            [document.getElementById('new').value]
        )
        document.getElementById('new').value = null
        setNewp(newp + 1)
        setVision(!vision)
    }

    const inputClick = () => {
        const arr = [];
        let i = 1;
        while (i < 11) {

            arr.push({
                gameNumber: Number(document.getElementById("uid").innerHTML),
                dateGame: document.getElementById('date').value,
                host: document.getElementById('host').innerText,
                slotNumber: i,
                roleGame: document.getElementById('role' + i).innerText,
                nick: document.getElementById('name' + i).innerHTML,
                foul: Number(document.getElementById('foul' + i).innerHTML),
                firstShot: document.getElementById('first' + i).checked,
                nine: document.getElementById('nine' + i).checked,
                win: ((document.getElementById('role' + i).innerHTML == 'Маф' || document.getElementById('role' + i).innerHTML == 'Дон') && document.getElementById('win').innerHTML == 'Мафия') || ((document.getElementById('role' + i).innerHTML == 'Мир' || document.getElementById('role' + i).innerHTML == 'Шер') && document.getElementById('win').innerHTML == 'Город') ? 1 : 0,
                extraSum: Number(document.getElementById('extraPlus' + i).value.replaceAll(',', '.')) + Number(document.getElementById('lh' + i).value.replaceAll(',', '.')) - Number(document.getElementById('extraMinus' + i).value.replaceAll(',', '.')),
                extraPlus: Number(document.getElementById('extraPlus' + i).value.replaceAll(',', '.')),
                extraMinus: Number(document.getElementById('extraMinus' + i).value.replaceAll(',', '.')),
                lh: Number(document.getElementById('lh' + i).value.replaceAll(',', '.')),
                gameNumberOfDay: Number(document.getElementById('gameNumber').value)
            })
            i++
        }
         if (window.confirm("Подтвердите внесение данных")) {
            axios.post(baseUrl2, arr);
            window.location.reload(true);
         }
    }
    const theme = createTheme({
        typography: {
            fontSize: 11,
        },
    });

    return (
        <div>
            <div className="zxzx">
                <ThemeProvider theme={theme}>
                    <div className="heh c">
                        <div className="he bb"><TextField id="gameNumber" label="Игра №" />  </div>
                        <div className="he bb"><BasicSelect ids="host" nameList={nameList} title={"Ведущий"} tr={140} /></div>
                        <div className="he bb"><BasicSelect ids="win" nameList={wins} title={"Победа"} tr={90} /></div>
                        <div className="he bb"><TextField id="date" type="date" sx={{ maxWidth: 140 }} />  </div>
                        <p id="uid" className="he">{input}</p>
                    </div>
                    <div className="heh">
                        {playerNumbers.map(data =>
                            <InputLine className="he2" number={data} nameList={nameList} roles={roles} fouls={fouls} />)}

                    </div>
                    <Button className="heh" variant="outlined" onClick={inputClick}>Отправить</Button>
                    <div className={!vision ? "no" : "ys"}><TextField size="small" id="new" label="Новый ник" inputProps={{ style: { padding: 7, height: "20px", width: "80px", } }} /></div>
                    <div className={!vision ? "no" : "ys"}><Button className="heh" variant="outlined" onClick={addNick}>Добавить</Button></div>
                </ThemeProvider>
            </div>

            <div className="zxzx">
                <h3>Лучший ход</h3>
                <TextField size="small" label="Игрок №" inputProps={{ style: { padding: 7, height: "20px", width: "80px", } }} />
                <div className="he6 bb2">{"ㅤ"}</div>
                <TextField size="small" label="1" inputProps={{ style: { padding: 7, height: "20px", width: "20px", } }} />
                <div className="he6 bb">,</div>
                <TextField size="small" label="2" inputProps={{ style: { padding: 7, height: "20px", width: "20px", } }} />
                <div className="he6 bb">,</div>
                <TextField size="small" label="3" inputProps={{ style: { padding: 7, height: "20px", width: "20px", } }} />
                <div className="nnnnn">
               <strong><input id="zxcc" className={"nnnn"} defaultValue={60} disabled={1}></input></strong> 
                <img onClick={start} className="nnn" src={play} alt="Link" />
                <img onClick={stop} className="nnn" src={pause} alt="Link" />               
                <img onClick={restart} className="nnn" src={refresh} alt="Link" />  
                </div>
                <div className="qwe"><Button variant="outlined" onClick={open}>+</Button></div>
                <center><h2>Голосование 1</h2></center>
                <InputRightLine />
                <center><h2>Голосование 2</h2></center>
                <InputRightLine />
                <center><h2>Голосование 3</h2></center>
                <InputRightLine />
                <center><h2>Голосование 4</h2></center>
                <InputRightLine />
                <center><h2>Голосование 5</h2></center>
                <InputRightLine />
                <center><h2>Голосование 6</h2></center>
                <InputRightLine />
            </div>
        </div>
    );
}

export default Add;