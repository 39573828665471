import sun from "./../../img/icons/mafia_png_21.png"
import "./style.css"

import { NavLink } from "react-router-dom"
import React from "react"

const NavBar = () => {
    const activeLink = 'nav-list__link nav-list__link--active';
    const normalLink = 'nav-list__link';

    return (
        <nav className="nav">
            <div className="container">
                <div className="nav-row">
                    <NavLink className="logo" to={"/"}><strong>Seven Reds</strong> Club</NavLink>
                    <button className="dark-mode-btn">
                        <img src={sun} alt="Light mode" className="dark-mode-btn__icon" />
                    </button>
                    <ul className="nav-list">
                        <li className="nav-list__item"><NavLink className={({ isActive }) => isActive ? activeLink : normalLink} to={"/"}>Главная</NavLink></li>
                        <li className="nav-list__item"><NavLink className={({ isActive }) => isActive ? activeLink : normalLink} to={"/resaultList/all"}>Игры</NavLink></li>
                        <li className="nav-list__item"><NavLink className={({ isActive }) => isActive ? activeLink : normalLink} to={"/rating"}>Рейтинг</NavLink></li>
                        <li className="nav-list__item"><NavLink className={({ isActive }) => isActive ? activeLink : normalLink} to={"/contacts"}>Контакты</NavLink></li>
                        {/* <li className="nav-list__item"><NavLink className={({ isActive }) => isActive ? activeLink : normalLink} to={"/add"}>Добавить</NavLink></li> */}
                    </ul>
                </div>
            </div>
        </nav>)
}
export default NavBar;