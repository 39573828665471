import "./style.css"

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import GameList from "./components/GameList";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { PieChart } from '@mui/x-charts/PieChart';


const Player = () => {
  const [out, setOut] = useState("");
  const [input, setInput] = useState({});
  const baseUrl = 'https://7reds.ru/api/mafia/getRating?full=1'
  const { name } = useParams();

  useEffect(() => { axios.get(baseUrl).then(res => setInput(res.data.ratingList.filter(el => el.nickName == name))) }, []);

  useEffect(() => {
    axios.get('https://7reds.ru/api/mafia/getGameList?name=' + name).then(res => setOut(res.data))
  }, []);

  const inputClic1 = (pp) => {
    let arr = [];
    for (let i = 0; i < pp.length; i++) {
      arr.push(i)
    }
    return arr
  }

  const inputClic3 = (pp) => {
    let arr = [];
    for (let i = 1; i <= pp; i++) {
      arr.push(i)
    }
    return arr
  }

  const inputClic4 = (pp) => {
    if(pp.target.textContent!=out.currentPage){
    // setOut({});
    axios.get('https://7reds.ru/api/mafia/getGameList?name=' + name + "&page=" + pp.target.textContent).then(res => setOut(res.data))
  }
  }

  return (
    <div>
      {input[0] != null ?
        <div >
          <div className="stats_1"><center><h1 className="stats_2">{name}</h1></center> </div>
          <center><h2 className="line7">{" Место - " + input[0].top + " Игр сыграно - " + input[0].games + " Побед - " + input[0].win}</h2></center>
          <center><h2 className="line7">{" Мирный - " + input[0].countMir + " Шериф - " + input[0].countCom + " Мафия - " + input[0].countMaf + " Дон - " + input[0].countDon}</h2></center>
          <div className="block">
            <ul>
              <li className="stats"><h2 className="line5">Процент побед общий </h2><h2 className="line6">{(input[0].procentWin).toFixed(2) + "%"}</h2><LinearProgress color="inherit" variant="determinate" value={input[0].procentWin} sx={{ width: 350 }} /></li>
              <li className="stats"><h2 className="line5">Процент побед за мирного</h2><h2 className="line6"> {(input[0].procenMir * 100).toFixed(2) + "%"}</h2><LinearProgress color="inherit" variant="determinate" value={input[0].procenMir * 100} sx={{ width: 350 }} /></li>
              <li className="stats"><h2 className="line5">Процент побед за мафа </h2><h2 className="line6">{(input[0].procenMaf * 100).toFixed(2) + "%"}</h2><LinearProgress color="inherit" variant="determinate" value={input[0].procenMaf * 100} sx={{ width: 350 }} /></li>
              <li className="stats"><h2 className="line5">Процент побед за шерифа </h2><h2 className="line6">{(input[0].procenCom * 100).toFixed(2) + "%"}</h2><LinearProgress color="inherit" variant="determinate" value={input[0].procenCom * 100} sx={{ width: 350 }} /></li>
              <li className="stats"><h2 className="line5">Процент побед за дона </h2><h2 className="line6">{(input[0].procenDon * 100).toFixed(2) + "%"}</h2><LinearProgress color="inherit" variant="determinate" value={input[0].procenDon * 100} sx={{ width: 350 }} /></li>
              <li className="stats"><h2 className="line5">️Первый убиенный</h2><h2 className="line6"> {input[0].firstShoot}</h2></li>
              <li className="stats"><h2 className="line5">Фолов </h2><h2 className="line6">{input[0].foll}</h2></li>
              <li className="stats"><h2 className="line5">Средний доп</h2><h2 className="line6"> {(input[0].extraMid).toFixed(2)}</h2></li>
              <li className="stats"><h2 className="line5">Попаданий в ЛХ</h2><h2 className="line6">{((input[0].procenLh * 100).toFixed(2)).replace(/0*$/,"").replace(/\.$/gm, '')+ "%"}</h2></li>
              <li className="stats"><h2 className="line5">Средний доп за ЛХ </h2><h2 className="line6">{(input[0].extraMidLh).toFixed(2)}</h2></li>
              <li className="stats"><h2 className="line5">Уходов в девятке </h2><h2 className="line6">{(input[0].nine)}</h2></li>
            </ul>
          </div>
          <div className="block"><div className="frame1"><center><p className="frame">Игровые дни:</p></center>{out && inputClic1(Object.keys(out.list)).map(ex => (<GameList name={name} ex={ex} out1={out} />))}{out ? <center>{<Stack spacing={2}>
            <Pagination hidePrevButton hideNextButton onClick={(e) => inputClic4(e)} count={out.pages} />
          </Stack>}</center> : <center><CircularProgress className="cirk" sx={{
            minWidth: 150,
            minHeight: 150,
            color: 'black',
          }} /></center>}</div></div>
          <div className="blockk">
            <PieChart
             margin={{ top: 0, bottom: 0, left: 0, right:0 }}
              series={[
                {
                  data: [
                    { label: 'Мафия', color: 'black', value: input[0].countMaf },
                    { label: 'Дон', color: 'rgb(144, 1, 203)', value: input[0].countDon },
                    { label: 'Мирный', color: 'Crimson', value: input[0].countMir },
                    { label: 'Шериф', color: 'Green', value: input[0].countCom },
                  ],
                  highlightScope: { faded: 'global', highlighted: 'item' },
                  innerRadius: 60,
                  outerRadius: 130,
                  paddingAngle: 3,
                  cornerRadius: 5,
                  startAngle: -90,
                  endAngle: 270,
                  display: "inline-table",
                  cx: 200,
                  cy: 130,
                },
              ]}
              width={430}
              height={400}
              slotProps={{
                legend: {
                  padding: 50, hidden: false, direction: 'row',
                  position: { vertical: 'bottom', horizontal: 'left' },
                },
              }}

            />
          </div>
        </div>
        : <center><CircularProgress className="cirk" sx={{
          minWidth: 250,
          minHeight: 250,
          color: 'black',
        }} /></center>}
    </div>
  );
}

export default Player;