import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


export default function BasicSelect({nameList,title,ids,val,arg,tr}) {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    if (event.target.value<5) arg(event.target.value);
    setAge(event.target.value);
  };

  return (
 
    <Box sx={{ minWidth: tr }}>
      <FormControl required fullWidth >
        <InputLabel >{title}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id={ids}
          value={val}
          label="Age"
          onChange={handleChange}
        >
          {nameList.map(data=><MenuItem value={data.nick!=null ?data.nick:data}>{data.nick!=null ?data.nick:data}</MenuItem>)}
        </Select>
      </FormControl>
    </Box>
  );
}
