import "./style.css"

import React, { useEffect, useState } from "react";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import Request from "./components/request";
import mock from "./../../img/icons/mock.jpg"

const Games = () => {

    const [input, setInput] = useState("");
    const baseUrl = 'https://7reds.ru/api/mafia/schedule'
    const [status, setStatus] = useState(0);

    let i = 0;
    useEffect(() => { axios.get(baseUrl).then((res) => (setInput(res.data), setStatus(res.status)))}, []);

    return (
        <main>
            {(status == 200 && input.length>0 )?
                <><center><h1 className="frame">Ближайшие игры</h1></center> 
                    {input.map((data) =>
                        <ul id={i++} className="frame">
                            <li><h2>Дата проведения:📅{data.dateevent}</h2></li>
                            <li>Место: <span class="ymaps-geolink">{"Ростов-на-Дону, " + data.locationevent.replace(new RegExp('_', 'g'), " ")}</span></li>
                            <li><h2>Время:⌚{data.timeevent.slice(0,-3)}</h2></li>
                            <li><h2>Цена:💰{data.price} ₽</h2></li>
                            <Request id={i} dataFromShedule={data} />
                        </ul>
                    )}
                </>
                
                : (status == 200 && input.length==0 )?<center><img src={mock} alt="Link" /></center>:<center><CircularProgress sx={{
                    minWidth: 250,
                    minHeight: 250,
                    color: 'black',
                }} /></center>}
        </main>
    );
}

export default Games;