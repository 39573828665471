import "./style.css"
import don from "./../../img/icons/don.svg"
import maf from "./../../img/icons/maf.svg"
import com from "./../../img/icons/com.svg"
import mir from "./../../img/icons/mir.svg"

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import CircularProgress from '@mui/material/CircularProgress';
import OneGame from "./components/OneGame"


const RewriteList = () => {

    const [input, setInput] = useState("");
    const [marker, setMarker] = useState(true);
    const baseUrl = 'https://7reds.ru/api/mafia/getGame'
    const { name } = useParams();

    const reload = () => {
        setInput(false);
        setMarker(!marker);
    }

    const getRoleIcone = (role) => {
        if (role == "Дон") return don;
        if (role == "Маф") return maf;
        if (role == "Шер") return com;
        if (role == "Мир") return mir;
    }

    let i = 1;
    useEffect(() => { axios.get(baseUrl).then(res => setInput(res.data.gamesList)) }, [marker]);

    return (
        <main>
            {input[0] != null ?
                <>
                    {input.map((data) =>
                        <OneGame arr={data} name={name} reload={reload} />
                    )}
                </>
                : <center><CircularProgress sx={{
                    minWidth: 250,
                    minHeight: 250,
                    color: 'black',
                }} /></center>}
        </main>
    );
}

export default RewriteList;