import "./style.css"
import { NavLink } from "react-router-dom";

import React from "react"

const Header = () => {
    return (<>
        <header className="header">
            <div className="header__wrapper">
                <h1 className="header__title">
                    <strong>Профессиональный клуб <em>7 Reds</em></strong><br />
                    по спортивной мафии
                </h1>
                <div className="header__text">
                    <p><i>этот город никогда не спит</i></p>
                </div>
                <NavLink to={"/games"} className="btn">Записаться на игру</NavLink>
            </div>
        </header>
    </>);
}

export default Header;