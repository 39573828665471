import React, { useState } from "react";
import axios from "axios";

import "./../style.css"

const Request = ({ id, dataFromShedule }) => {
    const [open, setOPen] = useState(false);
    const [out, setOut] = useState("");

    let i=0;

    const baseUrl1 = 'https://7reds.ru/api/mafia/list?date='
    const baseUrl = 'https://7reds.ru/api/mafia/schedule'

    const inputClick = (data) => {

        var d = new Date(data.dateevent);
        var datestring = d.getDate() + "." + (d.getMonth() + 1) + "." + d.getFullYear().toString().substr(-2);
        axios.get(baseUrl1 + datestring).then(res => setOut(res.data));
    }

    const signIn = (data, event) => {
        const regex = /^[А-Яа-яA-Za-z0-9]+.*/;
        if (document.getElementById("input " + event.target.id).value.length > 1 && regex.test(document.getElementById("input " + event.target.id).value)) {
            axios.post(baseUrl, {
                data: data.dateevent,
                userid: 4444,
                usernickname: document.getElementById("input " + event.target.id).value
            })
            document.getElementById("input " + event.target.id).value = null;
            setTimeout(() => {
                handleClick(dataFromShedule, true)
              }, 250)
        }   
    }

    const handleClick = (dataShedule, isOpen) => {
        setOPen(isOpen)
        inputClick(dataShedule)
    }
    return (
        <>
            <input className="input2" placeholder="Введите ваш ник" name="name" id={"input " + id} minLength="2" maxLength="30" pattern="^[А-Яа-яA-Za-z0-9]+.*" required />
            <button className="btn" id={id} onClick={(e) => signIn(dataFromShedule, e)}>Записаться</button>
            <button className="btn" onClick={(e) => handleClick(dataFromShedule, !open)}>Список</button>
            {out[0] != null && open && out.map((el) => <p key={i++}>{el}</p>)}
        </>
    )
}

export default Request